import React from 'react'
import ReactDOM from 'react-dom'
import getNonceOrThrow from '../shared/get-nonce-or-throw'
import Root from './components/root'
import { fromJson } from './options'

const init = () => {
    const main = document.getElementById('main')
    if (!main) return console.error('#main element not found')
    const options = fromJson(JSON.parse(main.innerText))
    const root = document.createElement('div')
    main.replaceWith(root)

    const nonce = getNonceOrThrow()

    ReactDOM.render(<Root nonce={nonce} options={options} />, root)
}

init()
