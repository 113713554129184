import React from 'react'
import { Result } from '../../shared/result'
import { Options } from '../options'
import AuthFatalError from '../../shared/components/auth-fatal-error'
import ProviderSelection from './provider-selection'
import AuthLayout from '../../shared/components/auth-layout'

const verifyImage = (url: string | null): Promise<string | null> => {
    if (!url) {
        return Promise.resolve(null)
    }
    const img = new Image()
    img.src = url!
    return new Promise((res) => {
        if (img.complete) {
            res(url)
        } else {
            img.onload = () => {
                res(url)
            }
            img.onerror = () => {
                res(null)
            }
        }
    })
}

const Page: React.FC<{ options: Result<Error, Options> }> = (props) => {
    const [urls, setUrls] = React.useState<{
        backgroundImageUrl: string | null
        logoImageUrl: string | null
    }>({ backgroundImageUrl: null, logoImageUrl: null })

    React.useEffect(() => {
        let isMounted = true
        if (props.options.hasError) {
            return
        }

        Promise.all([
            verifyImage(props.options.data.backgroundImageUrl),
            verifyImage(props.options.data.logoImageUrl),
        ]).then(([backgroundImageUrl, logoImageUrl]) => {
            if (isMounted) {
                if (backgroundImageUrl === null || logoImageUrl === null) {
                    return
                }
                setUrls({ backgroundImageUrl, logoImageUrl })
            }
        })
        return () => {
            isMounted = false
        }
    }, [])

    return (
        <AuthLayout
            backgroundImageUrl={urls.backgroundImageUrl}
            logoImageUrl={urls.logoImageUrl}
        >
            {props.options.hasError && (
                <AuthFatalError>{props.options.error.message}</AuthFatalError>
            )}
            {!props.options.hasError && (
                <ProviderSelection
                    options={{
                        ...props.options.data,
                        backgroundImageUrl: urls.backgroundImageUrl,
                        logoImageUrl: urls.logoImageUrl,
                    }}
                />
            )}
        </AuthLayout>
    )
}

export default Page
