const getNonceOrThrow = (): string => {
    const nonce = document.head
        .querySelector('meta[property=csp-nonce]')
        ?.getAttribute('content')

    if (nonce === undefined || nonce === null) {
        throw new Error(
            'Could not initialize app, CSP nonce meta tag is missing from document header.'
        )
    }

    return nonce
}

export default getNonceOrThrow
