import React, { PropsWithChildren } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import {
    StyledEngineProvider,
    ThemeProvider,
    createTheme,
} from '@mui/material/styles'
import * as Theme from 'bizzkit/style/theme'
import { CacheProvider, EmotionCache } from '@emotion/react'

const theme = createTheme(Theme.current)

export const extras = {
    primaryLight: '#C7CAD4',
}

export default React.memo<PropsWithChildren<{ cache?: EmotionCache }>>(
    function Theme(props) {
        return (
            <StyledEngineProvider injectFirst>
                {props.cache ? (
                    <CacheProvider value={props.cache}>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            {props.children}
                        </ThemeProvider>
                    </CacheProvider>
                ) : (
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        {props.children}
                    </ThemeProvider>
                )}
            </StyledEngineProvider>
        )
    }
)
