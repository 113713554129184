export type Result<TError, TData> = Failed<TError> | Succeeded<TData>

export type Failed<T> = {
    hasError: true
    error: T
}

export type Succeeded<T> = {
    hasError: false
    data: T
}

export const fail = <TError>(error: TError): Failed<TError> => ({
    hasError: true,
    error,
})

export const succeed = <TData>(data: TData): Succeeded<TData> => ({
    hasError: false,
    data,
})

export default {
    fail,
    succeed,
}
