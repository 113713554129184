import React from 'react'
import { Result } from '../../shared/result'
import { Options } from '../options'
import Page from './page'
import Styling from '../../shared/components/styling'

const Root = React.memo<{ nonce: string; options: Result<Error, Options> }>(
    function Root(props) {
        return (
            <Styling nonce={props.nonce}>
                <Page options={props.options} />
            </Styling>
        )
    }
)

export default Root
