import React from 'react'
import createCache from '@emotion/cache'

import Theme from '../../shared/theme'

const Styling = React.memo<
    React.PropsWithChildren<{
        nonce?: string
    }>
>(function Styling({ nonce, children }) {
    const cache = React.useMemo(
        () =>
            createCache({
                key: 'css',
                prepend: true,
                nonce,
            }),
        [nonce]
    )

    return <Theme cache={cache}>{children}</Theme>
})

export default Styling
